import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
import { environment } from "../../environments/environment";
import { timeout, catchError } from "rxjs/operators";
import { of } from "rxjs/observable/of";
@Injectable()
export class DocumentsService {
  constructor(private httpClient: HttpClient) {}

  searchIA(world, search_key, start_date, end_date) {
    /*  return this.httpClient
    .get(environment.api.iASearch
      + start_date
      + '/' + end_date
      + '?word=' + world)
    .map(data => data);
*/
    return this.httpClient
      .get(
        environment.api.iASearch +
          start_date +
          "/" +
          end_date +
          "?word=" +
          world
      )
      .pipe(
        timeout(20000),
        catchError((e) => {
          // do something on a timeout
          return of(null);
        })
      );
  }

  obtenerSucursalByID(customer_branch_id) {
    return this.httpClient
      .get(environment.api.sucursalPorId + "/" + customer_branch_id)
      .map((data) => data);
  }

  obtenerArchivosPorZonaRuta(
    company_branch_id,
    zone_id,
    route_id,
    file_track_id,
    start_date,
    end_date
  ) {
    return this.httpClient
      .get(
        environment.api.archivosPorZona +
          "/" +
          company_branch_id +
          "/" +
          zone_id +
          "/" +
          route_id +
          "/" +
          file_track_id +
          "/" +
          start_date +
          "/" +
          end_date
      )
      .map((data) => data);
  }

  updateDocuentSt(document_id) {
    // console.log(">>>> Folder id = " + documentos.folderId +" documentos.customer_id "  +documentos.customer_id);
    /*
.put(environment.api.actualizarArchivoFirma, documento, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .map((data) => data);
*/
    return this.httpClient
      .put(environment.api.documentStatus + "/" + document_id, null, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .map((data) => data);
  }
  obtenerArchivosGeneral(documentos, is_general_, container_id, status_id) {
    // console.log(">>>> Folder id = " + documentos.folderId +" documentos.customer_id "  +documentos.customer_id);

    return this.httpClient
      .get(
        environment.api.archivosgeneral +
          "/" +
          localStorage.getItem("customerBranchId") +
          "/" +
          localStorage.getItem("customerBranch") +
          "/" +
          documentos.start_date +
          "/" +
          documentos.end_date +
          "/" +
          documentos.customer_id +
          "/" +
          +documentos.customer_branch_id +
          "/" +
          documentos.folderId +
          "/" +
          container_id +
          "/" +
          status_id +
          "/" +
          is_general_ +
          "?from=1&per_page=1000",
        {
          headers: { language_id: "1" },
        }
      )
      .map((data) => data);
  }

  obtenerArchivos(documentos, container_id) {
    //console.log(">>>> folder id " + documentos.folderId);
    return this.httpClient
      .get(
        environment.api.archivos +
          "/" +
          localStorage.getItem("customerBranchId") +
          "/" +
          localStorage.getItem("customerBranch") +
          "/" +
          documentos.start_date +
          "/" +
          documentos.end_date +
          "/" +
          documentos.company_id +
          "/" +
          documentos.company_branch_id +
          "/" +
          documentos.folderId +
          "/" +
          container_id +
          "?from=1&per_page=1000",
        {
          headers: { language_id: "1" },
        }
      )
      .map((data) => data);
  }
  obtenerClienteArchivosVisor(
    documentos,
    estatus,
    container_id,
    fileName,
    customer_branch_id
  ) {
    //console.log("API caller.....");
    if (fileName === undefined) {
      fileName = "";
    }
    return this.httpClient
      .get(
        environment.api.archivosVisor +
          "/" +
          documentos.start_date +
          "/" +
          documentos.end_date +
          "/" +
          documentos.folderId +
          "/" +
          estatus +
          "/" +
          container_id +
          "/" +
          documentos.customer_id +
          "/" +
          customer_branch_id +
          "?file_name=" +
          fileName,
        {
          headers: {
            token: sessionStorage.getItem("access_token"),
            language_id: "1",
          },
        }
      )
      .map((data) => data);
  }

  obtenerClienteArchivosVisorv1(
    documentos,
    estatus,
    container_id,
    fileName,
    customer_branch_id,
    year_folder
  ) {
    if (fileName === undefined) {
      fileName = "";
    }
    return this.httpClient
      .get(
        environment.api.archivosVisor +
          "/-101010/" +
          "-101010/" +
          documentos.folderId +
          "/" +
          estatus +
          "/" +
          container_id +
          "/" +
          documentos.customer_id +
          "/" +
          customer_branch_id +
          "/" +
          year_folder +
          "?file_name=" +
          fileName,
        {
          headers: {
            token: sessionStorage.getItem("access_token"),
            language_id: "1",
          },
        }
      )
      .map((data) => data);
  }

  obtenerClienteArchivos(documentos, estatus, container_id) {
    return this.httpClient
      .get(
        environment.api.archivos +
          "/" +
          documentos.start_date +
          "/" +
          documentos.end_date +
          "/" +
          documentos.folderId +
          "/" +
          estatus +
          "/" +
          container_id,
        {
          headers: {
            token: sessionStorage.getItem("access_token"),
            language_id: "1",
          },
        }
      )
      .map((data) => data);
  }

  obtenerArchivosAprobados(documentos, container_id) {
    /**/
    return this.httpClient
      .get(
        environment.api.archivos +
          "/approved/" +
          localStorage.getItem("customerBranchId") +
          "/" +
          localStorage.getItem("customerBranch") +
          "/" +
          documentos.start_date +
          "/" +
          documentos.end_date +
          "/" +
          documentos.company_id +
          "/" +
          documentos.company_branch_id +
          "/" +
          documentos.folderId +
          "/" +
          container_id +
          "?from=1&per_page=1000"
      )
      .map((data) => data);
  }

  obtenerArchivosPendientes(documentos, container_id) {
    return this.httpClient
      .get(
        environment.api.archivos +
          "/pending/" +
          localStorage.getItem("customerBranchId") +
          "/" +
          localStorage.getItem("customerBranch") +
          "/" +
          documentos.start_date +
          "/" +
          documentos.end_date +
          "/" +
          documentos.company_id +
          "/" +
          documentos.company_branch_id +
          "/" +
          documentos.folderId +
          "/" +
          container_id +
          "?from=1&per_page=1000"
      )
      .map((data) => data);
  }

  obtenerArchivosRechazados(documentos, container_id) {
    return this.httpClient
      .get(
        environment.api.archivos +
          "/rejected/" +
          localStorage.getItem("customerBranchId") +
          "/" +
          localStorage.getItem("customerBranch") +
          "/" +
          documentos.start_date +
          "/" +
          documentos.end_date +
          "/" +
          documentos.company_id +
          "/" +
          documentos.company_branch_id +
          "/" +
          documentos.folderId +
          "/" +
          container_id +
          "?from=1&per_page=1000"
      )
      .map((data) => data);
  }

  borrarArchivo(documento) {
    // console.log("Ejecutando api borrar" + documento.data.document_id);
    const options = {
      headers: {},
      body: {
        data: [
          {
            document_id: documento.data.document_id,
            key: documento.data.key,
            bucket_name: documento.data.bucket_name,
          },
        ],
      },
    };
    this.httpClient
      .delete(environment.api.documentos, options)
      .subscribe((s) => {});
  }

  actualizarArchivo(documento) {
    ///customers/documents/customers-exist
    return this.httpClient
      .put(environment.api.actualizarArchivoFirma, documento, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .map((data) => data);
  }
}
