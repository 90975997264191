//////Comentario para productivo cambiado
export const host_service = {
  host: "https://ema-customers-production.aion-systems.com",
};
export const environment = {
  production: true,
  ambiente: "PRDv1.0",
  descrpcion_ambiente:
    "Ambiente de productivo utilizado para la operación de la empresa.",
  api: {
    login: host_service.host + "/v1/ema-system/customer-portal/login",
    refreshToken:
      host_service.host + "/v1/ema-system/customer-portal/refresh-token",
    menu: host_service.host + "/v1/ema-system/administration/menu",
    logout: host_service.host + "/v1/ema-system/both/logout",
    calendario: host_service.host + "/v1/ema-system/customers/calendar",
    catalogoCiudad: host_service.host + "/v1/ema-system/catalogs/cities",
    cliente: host_service.host + "/v1/ema-system/customers-portal",
    listaClientes: host_service.host + "/v1/ema-system/portal/customers",
    documentos: host_service.host + "/v1/ema-system/customers/documents",
    sucursales: host_service.host + "/v1/ema-system/customers-branches-portal",
    sucursalPorClienteAdmin:
      host_service.host + "/v1/ema-system/portal-admin/customers-branches",
    sucursalPorClienteAdminByKeyword:
      host_service.host +
      "/v1/ema-system/portal-admin/customers-branches/by-Keyword",
    sucursalPorCliente:
      host_service.host + "/v1/ema-system/portal/customers-branches",
    sucursalPorRuta:
      host_service.host + "/v1/ema-system/customers/branches/zones/routes",
    reporteDocumentos: host_service.host + "/v1/ema-system/dashboards/data",
    reporteDocumentosDetalle:
      host_service.host + "/v1/ema-system/reports/customers/documents-list",
    sucursalPorId:
      host_service.host + "/v1/ema-system/portal/customers-branches-by-id",
    archivosPorZona:
      host_service.host + "/v1/ema-system/customers/documents/zones/routes",
    archivos: host_service.host + "/v1/ema-system/customers/documents-list",
    actualizarArchivo:
      host_service.host + "/v1/ema-system/customers/documents/customers-exist",
    actualizarArchivoFirma:
      host_service.host +
      "/v1/ema-system/customers/documents/customers-exist/sign",
    archivosVisor:
      host_service.host + "/v1/ema-system/optim/customers/documents-list",
    archivosgeneral:
      host_service.host + "/v1/ema-system/customers/documents-list/general",
    empleados: host_service.host + "/v1/ema-system/employees",
    catalogoEstados: host_service.host + "/v1/ema-system/catalogs/states",
    catalogoIndustrias:
      host_service.host + "/v1/ema-system/catalogs/industries",
    sucursalesCompania: host_service.host + "/v1/ema-system/company-branches",
    notificacionesFiltro:
      host_service.host + "/v1/ema-system/employees/notifications-filters",
    notificaciones:
      host_service.host + "/v1/ema-system/employees/notifications",
    enviaNotificaciones:
      host_service.host + "/v1/ema-system/send/notifications",
    catalogoPais: host_service.host + "/v1/ema-system/catalogs/countries",
    carpetas: host_service.host + "/v1/ema-system/customers/path-list",
    rutas: host_service.host + "/v1/ema-system/customers/routes",
    rutasDisponibleEmpleados:
      host_service.host + "/v1/ema-system/services/service-routes/availables",
    usuarios: host_service.host + "/v1/ema-system/portal/customers/users",
    administracionUsuarios:
      host_service.host + "/v1/ema-system/users-administration",
    zonas: host_service.host + "/v1/ema-system/customers/zones",
    validarZonasCliente:
      host_service.host +
      "/v1/ema-system/customers-branches-portal/zone-route-validation",
    aceptarCarpeta:
      host_service.host + "/v1/ema-system/customers/folder-accept",
    notificationgeneral:
      host_service.host + "/v1/ema-system/emasystem/notification-sender",
    roleConfiguration:
      host_service.host + "/v1/ema-system/services/role-configuration/",
    folderAccepted: host_service.host + "/v1/ema-system/customers/reports",
    iASearch:
      host_service.host + "/v1/ema-system/optim/customers/documents-ia/",
    logMonitor: host_service.host + "/v1/ema-system/customers/log",
    exceptionDocument:
      host_service.host + "/v1/ema-system/customers/document-exception",
    getExceptionDocument:
      host_service.host + "/v1/ema-system/customers/document-exception",
    deleteExceptionDocument:
      host_service.host + "/v1/ema-system/customers/document-exception",
    notificationSMSEmail:
      host_service.host + "/v1/ema-system/send/notifications/sms/basic",
    documentStatus:
      host_service.host + "/v1/ema-system/customer/document-status",
  },
};
